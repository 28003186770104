const sections = Array.from(document.querySelectorAll('.block'));

const displayThreshold = 0.1;
const marginY = Math.round(window.innerHeight * displayThreshold);

const observerOptions = {
    threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    rootMargin: `-${marginY}px 0px`,
};

const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        if (!entry.isIntersecting) {
            return;
        }

        entry.target.classList.add('block--active');
        observer.unobserve(entry.target);
    });
}, observerOptions);

sections.forEach(section => observer.observe(section));
