document.addEventListener('DOMContentLoaded', () => {
    const headings = document.querySelectorAll('.article h2[id]');
    const navLinks = document.querySelectorAll('.article-menu__link');

    const setActiveNav = id => {
        navLinks.forEach(link => {
            if (link.getAttribute('href') === `#${id}`) {
                link.classList.add('article-menu__link--active');
            } else {
                link.classList.remove('article-menu__link--active');
            }
        });
    };

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveNav(entry.target.id);
                }
            });
        },
        {
            root: null,
            threshold: 0.6,
        },
    );

    headings.forEach(heading => observer.observe(heading));
});
